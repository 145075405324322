import React, { useState } from 'react';
import "../DashboardPages.scss";

export const PaymentModalContent = () => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPaymentMethod(event.target.value);
    };

    return (
        <>
            <select onChange={handleSelectChange} value={selectedPaymentMethod}>
                <option value="">Select a payment method</option>
                <option value="Credit Card">Credit Card</option>
                <option value="Paypal">Paypal</option>
                <option value="Gift Card">Gift Card</option>
            </select>

            {selectedPaymentMethod === 'Credit Card' && (
                <div className='payment-method'>
                    <h2>Insert your credit card data</h2>
                    <p>Name</p>
                    <input type="text" />
                    <p>Number</p>
                    <input type="number" />
                    <p>Expiration date</p>
                    <input type="date" />
                    <p>Security Number</p>
                    <input type="number" />
                    <button className="payment-button" type='submit'>Add Credit Card</button>

                </div>
            )}

            {selectedPaymentMethod === 'Paypal' && (
                <div className='payment-method'>
                    <h2>Dados de acesso Paypal</h2>
                    <p>Usuário</p>
                    <input type="email" />
                    <p>Senha</p>
                    <input type="password" />
                    <button className="payment-button" type='submit'>Login</button>
                </div>
            )}

            {selectedPaymentMethod === 'Gift Card' && (
                <div className='payment-method'>
                    <h2>Insert your Gift Card number here</h2>
                    <input type="text" />
                    <button className="payment-button" type='submit'>Apply Gift Card</button>

                </div>
            )}
        </>
    );
};
