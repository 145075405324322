import React, { ReactNode, useEffect, useRef } from 'react';
import './Modal.scss';

interface ModalProps {
    children: ReactNode;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const closeModal = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeModal);
        return () => {
            document.removeEventListener('mousedown', closeModal);
        };
    },);

    return (
        <div className="modalEdit" ref={modalRef}>
            <div className="modal-content">
                {children}
            </div>
        </div>
    );
};

export default Modal;
