import missionIcon from 'assets/mission.webp';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import "../Pages.scss";



const Mission = () => {
  return (
    <>
      <Header />
      <main className="text__main">
        <section className="text__section">
          <h1>Mission</h1>
          <h3>Our mission is to empower individuals with tools that safeguard their most valuable memories and information, ensuring nothing of significance is ever forgotten.</h3>
          <h1>Vision</h1>
          <p>"Through Zenminder's AI-driven solutions, the world will embrace an era of effortless organization, where missed opportunities are a thing of the past and every individual thrives in optimized productivity."</p>
          <h1>Corporative Values</h1>
          <ul className="ul__values">
            <li className="li__values">Innovation
              <p>The lifeblood of business evolution, innovation disrupts the ordinary to create the extraordinary. It's about envisioning what's next and turning novel ideas into impactful realities.</p>
            </li>
            <li className="li__values">Trust
              <p>Trust is the foundation of every successful venture. It fosters transparency, promotes loyalty, and when consistently upheld, becomes a brand's most valuable asset.</p>
            </li>
            <li className="li__values">Hard Work
              <p>Hard work is the engine that drives achievement. It's the unwavering dedication and relentless pursuit of goals, even in the face of challenges.</p>
            </li>
            <li className="li__values">Team Work
              <p>Teamwork magnifies individual strengths and bridges gaps. Through collective effort and collaboration, it propels organizations to achieve more than the sum of their parts.</p>
            </li>
            <li className="li__values">Team appreciation
              <p>Recognizing and valuing team contributions fuels motivation and loyalty. It's the affirmation that every role matters and that together, every milestone achieved is a shared success.</p>
            </li>
          </ul>
        </section>
        <p><img src={missionIcon} alt="Artistic representation of our mission." className="img__background" />
        </p>
      </main>
      <Footer />

    </>
  );
};

export default Mission;
