import termsIcon from 'assets/terms.webp';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import "../Pages.scss";


const TermsOfUse = () => {
  return (
    <div>
      <Header />
      <main className="text__main">
        <section className="text__section">
          <h1>Terms of Use for Zenminder</h1>
          <h2>Acceptance of Terms</h2>
          <p>By accessing and using the Zenminder Application, you agree to be bound by these Terms of Use. If you do not agree to these Terms, do not use the Application.</p>

          <h2>Description of Service</h2>
          <p>Zenminder provides a productivity solution incorporating artificial intelligence for task management and reminders. The Service is provided and is subject to change without notice.</p>

          <h2>User Obligations</h2>
          <p>You agree to use the Service only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the Service by any third party.</p>

          <h2>Intellectual Property</h2>
          <p>All intellectual property rights in the Application and the Service, including but not limited to design, text, graphics, and the selection and arrangement thereof, are owned by Zenminder.</p>

          <h2>Privacy</h2>
          <p>Your use of the Application is also governed by our Privacy Policy, which is incorporated into these Terms by reference.</p>

          <h2>Limitation of Liability</h2>
          <p>Zenminder shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the Service; (b) any conduct or content of any third party on the Service.</p>

          <h2>Warranty Disclaimer</h2>
          <p>Zenminder and its affiliates do not warrant that the Service will function uninterrupted, secure, or available at any particular time or location; any errors or defects will be corrected; the Service is free of viruses or other harmful components; or the results of using the Service will meet your requirements.</p>

          <h2>Modification of Terms</h2>
          <p>Zenminder reserves the right, at our sole discretion, to modify or replace these Terms at any time. Your continued use of the Service following the posting of any changes to these Terms constitutes acceptance of those changes.</p>

          <h2>Termination</h2>
          <p>Zenminder may terminate or suspend access to the Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>

          <h2>Governing Law</h2>
          <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Zenminder operates, without regard to its conflict of law provisions.</p>

          <p>Contact us if you have questions about this terms of use, contact us at contact@zenminder.com.</p>
        </section>
        <p><img src={termsIcon} alt="Artistic representation of our Terms of Use." className="img__background" /></p>

      </main>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
