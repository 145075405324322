import { IPhoneNumberVerifiedResponseDTO, IVerifyCodeTypedFromUserRequestDTO } from "dtos/phone-number-verification.dto";
import { TBackendResponse } from "dtos/utils.dto";

class PhoneVerificationService {
    async sendVerificationCodeToUser(phoneNumber: string, userToken: string): Promise<TBackendResponse> {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({ phoneNumber })
        };

        return fetch('https://us-central1-zenminder-2e6d5.cloudfunctions.net/sendVerificationCodeToUser', options)
            .then(response => response.json());
    }

    async verifyCodeTypedFromUser(data: IVerifyCodeTypedFromUserRequestDTO, userToken: string): Promise<TBackendResponse> {
        const url = 'https://us-central1-zenminder-2e6d5.cloudfunctions.net/verifyCodeTypedFromUser';

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify(data)
        };

        return fetch(url, options)
            .then(response => response.json());
    }

    async getAllVerifiedNumbers(userToken: string): Promise<IPhoneNumberVerifiedResponseDTO> {
        console.log('User token:', userToken);

        const url = `https://us-central1-zenminder-2e6d5.cloudfunctions.net/getAllVerifiedNumbers`;
        const options = {
            method: 'GET',
            headers: {
                'authorization': `Bearer ${userToken}`
            }
        };

        return fetch(url, options)
            .then(response => {
                console.log('Response from getAllVerifiedNumbers:', response);
                return response.json();
            });
    }
}
export const phoneVerificationService = new PhoneVerificationService();
