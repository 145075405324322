import { IPhoneNumberVerified } from 'dtos/phone-number-verification.dto';
import { IUser, gUserState } from 'models/user.model';
import { DashboardAside } from 'pages/Dashboard/components/DashboardAside/DashboardAside';
import DashboardHeader from 'pages/Dashboard/components/DashboardHeader/DashboardHeader';
import VerifiedPhoneList from 'pages/Dashboard/components/VerifiedPhoneList/VerifiedPhoneList';
import { useCallback, useEffect, useState } from 'react';
import PhoneInput, { Value, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useRecoilValue } from 'recoil';
import { phoneVerificationService } from 'services/PhoneService';
import { ModalVerification } from '../../components/ModalVerification/ModalVerification';
import "../DashboardPages.scss";

const VerifyPhoneNumber = () => {
    const [verifiedPhones, setVerifiedPhones] = useState<IPhoneNumberVerified[]>([]);
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<Value | undefined>(undefined);
    const user: IUser = useRecoilValue(gUserState);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getVerifiedPhoneList = useCallback(async () => {
        console.log('Fetching verified phone list with token:', user.token);
        const response = await phoneVerificationService.getAllVerifiedNumbers(user.token);
        console.log('Verified phones response:', response);
        setVerifiedPhones(response.phones);
    }, [user.token]);

    useEffect(() => {
        getVerifiedPhoneList();
    }, [getVerifiedPhoneList]);

    const openModalAndSendCode = async () => {
        const phone = parsePhoneNumber(currentPhoneNumber as string);
        if (phone) {
            console.log('Sending verification code with token:', user.token);
            const response = await phoneVerificationService.sendVerificationCodeToUser(phone.number, user.token);
            console.log('Response from sendVerificationCodeToUser:', response);
            if (response.success) {
                setIsModalOpen(true);
            }
        }
    };

    const verifyPhoneNumber = async (verificationCode: string) => {
        if (currentPhoneNumber) {
            const parsedPhone = parsePhoneNumber(currentPhoneNumber);

            if (parsedPhone) {
                const requestData = {
                    code: verificationCode,
                    country: parsedPhone.country || 'BR',
                    countryCallingCode: parsedPhone.countryCallingCode,
                    nationalNumber: parsedPhone.nationalNumber,
                    number: parsedPhone.number
                };

                const response = await phoneVerificationService.verifyCodeTypedFromUser(requestData, user.token);
                if (response.success) {
                    setIsModalOpen(false);
                    await getVerifiedPhoneList();
                }
            }
        }
    };

    return (
        <div className='main-dashboard-pages'>
            <DashboardHeader />
            <DashboardAside />
            <div className='verifyOutlet'>
                <h1 className="verifyTitle">Phone number verification</h1>
                <div>
                    <label htmlFor="phoneName"><h2>Name this phone:</h2></label>
                    <input type="text" id="phoneName" name="phoneName" />
                </div>
                <PhoneInput
                    international
                    defaultCountry="BR"
                    value={currentPhoneNumber}
                    onChange={setCurrentPhoneNumber} />
                <button className='submit-button' onClick={openModalAndSendCode}>Verify your phone</button>
                <ModalVerification
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    verifyPhoneNumber={verifyPhoneNumber}
                />
                <div className='verifiedNumbersContainer'>
                    <VerifiedPhoneList verifiedPhones={verifiedPhones} />
                </div>
            </div>
        </div>
    );
};

export default VerifyPhoneNumber;