export enum EFrontendErrorID {
    errorWhenTryingToLoginWithGoogleProvider = 'errorWhenTryingToLoginWithGoogleProvider',
    errorWhenTryingToSignUpWithGoogleProvider = 'errorWhenTryingToSignUpWithGoogleProvider',
    errorWhenTryingToSignUpWithEmail = "errorWhenTryingToSignUpWithEmail",
    errorWhenTryingToLoginWithEmail = "errorWhenTryingToLoginWithEmail",
    errorWhenTryingToLogoutWithEmail = "errorWhenTryingToLogoutWithEmail"
}

export enum EBackendErrorIDS {
    sendAuthenticationTemplateFromError = 'sendAuthenticationTemplateFromError',
    verificationCodeNotFound = "verificationCodeNotFound",
    httpMethodMustBeGET = "httpMethodMustBeGET"
}