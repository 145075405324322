import { IReminder } from 'models/task.model';
import { gUserState } from 'models/user.model';
import { Spinner } from 'pages/Dashboard/components/Spinner/Spinner';
import TaskListCard from 'pages/Dashboard/components/TaskListCard/TaskListCard';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { remindersService } from 'services/RemindersService';
import AddIcon from "../../../../assets/icons/add.png";
import TasksModal from '../TasksModal/TasksModal';
import './TaskList.scss';

const TaskList: React.FC = () => {
    const [tasks, setTasks] = useState<IReminder[]>([]);
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const user = useRecoilValue(gUserState);
    const [newTask, setNewTask] = useState<IReminder>({ id: '', title: '', dueDate: '', imageURL: '', userID: undefined });
    const [loading, setLoading] = useState(false);


    const refreshList = useCallback(async () => {
        if (user && user.token) {
            setLoading(true);
            const updatedTasks = await remindersService.getRemindersList(user.token);
            setTasks(updatedTasks);
            setLoading(false);
        }
    }, [user]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const createReminder = async () => {
        if (user && user.token && newTask) {
            try {
                await remindersService.createReminder(user.token, newTask);
                setShowAddTaskModal(false);
                refreshList();
            } catch (error) {
                console.error("Failed to create reminder:", error);
            }
        }
    };

    return (
        <div className="task-list-container">
            {loading && <Spinner />}
            <nav className='tasklist-nav'>
                <button className="floating-add-icon" onClick={() => setShowAddTaskModal(true)}>
                    <img src={AddIcon} alt="Add" className="task-nav-icon" />
                </button>
            </nav>
            {showAddTaskModal && (
                <TasksModal
                    showModal={showAddTaskModal}
                    setShowModal={setShowAddTaskModal}
                    setTasks={setTasks}
                    task={newTask}
                    setTask={setNewTask}
                    isEditMode={false}
                    onSubmit={createReminder}
                    tasks={tasks}
                />
            )}
            <h1>Reminders</h1>

            <div className="div__reminders-mother">
                <div className="other-scale-reminders reminders">
                    {
                        tasks.length > 0 ? (
                            <ul className='ulTasks'>
                                {tasks.map((task) => (
                                    <TaskListCard
                                        key={task.id}
                                        task={task}
                                        refreshTasks={refreshList}
                                    />
                                ))}
                            </ul>
                        ) : (
                            <p>No reminders scheduled.</p>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default TaskList;
