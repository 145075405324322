import { DashboardAside } from 'pages/Dashboard/components/DashboardAside/DashboardAside';
import DashboardHeader from 'pages/Dashboard/components/DashboardHeader/DashboardHeader';
import { useState } from 'react';
import AddIcon from "../../../../assets/icons/add.png";
import "../DashboardPages.scss";
import { PaymentModal } from '../Payment/PaymentModal';

const PaymentDetails = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    return (
        <div className='main-dashboard-pages'>
            <DashboardHeader />
            <DashboardAside />
            <div className='payments-main-div'>
                <h1>My payment methods</h1>
                <button className='icon-and-name' onClick={openModal}>
                    <img src={AddIcon} alt="add payment method" className='icon-default' />
                    <p>Add payment method</p>
                </button>

                {isModalOpen && <PaymentModal closeModal={() => setIsModalOpen(false)} />}
            </div>

        </div>
    );
};

export default PaymentDetails;
