// InitApp.tsx
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { gUserState } from 'models/user.model';
import { authService } from 'services/AuthService';

const InitApp = () => {
    const setUserState = useSetRecoilState(gUserState);

    useEffect(() => {
        authService.onPageLoad(setUserState).then((result) => {
            if (result.user) {
                // navigate('/app/dashboard', { state: { user: result.user } });
            }

            return () => result.unsubscribe();
        })
    }, [setUserState]);

    return null;
};

export default InitApp;
