import Footer from 'components/Footer/Footer';
import { HomePageContent } from 'pages/Home/HomePageContent/Index';
import Header from "../../components/Header/Header";
import './HomePage.scss';


function HomePage() {
    return (
        <div className='homepage-body'>
            <Header />
            <HomePageContent />
            <Footer />
        </div>
    );
}

export default HomePage;
