import { PaymentModalContent } from 'pages/Dashboard/pages/Payment/PaymentModalContent';
import React, { useEffect, useRef } from 'react';
import "../DashboardPages.scss";

type PaymentModalProps = {
    closeModal: () => void;
};

export const PaymentModal: React.FC<PaymentModalProps> = ({ closeModal }) => {
    const dialog = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        const currentDialog = dialog.current;

        if (currentDialog) {
            currentDialog.showModal();
        }
    }, []);

    const handleCancel = (event: React.SyntheticEvent) => {
        event.preventDefault();
        closeModal();
    };

    const handleClickOutside = (event: React.MouseEvent) => {
        if (event.target === dialog.current) {
            closeModal();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    };

    return (
        <dialog
            className="payment-modal"
            ref={dialog}
            onCancel={handleCancel}
            onClick={handleClickOutside}
            onKeyDown={handleKeyDown}
        >
            <button className="close-modal-button" onClick={closeModal}>&times;</button>
            <PaymentModalContent />
        </dialog>
    );
};
