import { IReminder } from 'models/task.model';
import { gUserState } from 'models/user.model';
import { Spinner } from 'pages/Dashboard/components/Spinner/Spinner';
import TasksModal from 'pages/Dashboard/components/TasksModal/TasksModal';
import { WhatsappTemplateItem } from 'pages/Dashboard/components/WhatsappTemplateItem/WhatsappTemplateItem';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { remindersService } from 'services/RemindersService';

interface TaskListCardProps {
    task: IReminder;
    refreshTasks: () => void;
}

const TaskListCard: React.FC<TaskListCardProps> = ({ task, refreshTasks }) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const user = useRecoilValue(gUserState);
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState<IReminder[]>([]);


    const editTask = async () => {
        if (user && user.token) {
            setLoading(true);
            try {
                await remindersService.updateReminder(user.token, task.id, task);
                setShowEditModal(false);
                refreshTasks();
            } catch (error) {
                console.error("Failed to edit task:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const deleteTask = async () => {
        if (user && user.token) {
            setLoading(true);
            try {
                await remindersService.deleteReminder(user.token, task.id);
                refreshTasks();
            } catch (error) {
                console.error("Failed to delete task:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    if (loading) return <Spinner />;

    return (
        <div className="card-render-n-pic">
            <li className="liTask">
                <WhatsappTemplateItem
                    imageURL={task.imageURL}
                    title={task.title}
                    dueDate={task.dueDate}
                    categories={task.categories}
                    onEdit={() => setShowEditModal(true)}
                    onDelete={deleteTask}
                />

            </li>
            {showEditModal && (
                <TasksModal
                    showModal={showEditModal}
                    setShowModal={setShowEditModal}
                    task={task}
                    setTask={() => { }}
                    isEditMode={true}
                    onSubmit={editTask}
                    tasks={tasks}
                    setTasks={setTasks}
                />
            )}
        </div>
    );
};

export default TaskListCard;
