import { gUserState } from 'models/user.model';
import { DashboardAside } from 'pages/Dashboard/components/DashboardAside/DashboardAside';
import DashboardHeader from 'pages/Dashboard/components/DashboardHeader/DashboardHeader';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import EditIcon from "../../../../assets/icons/edit.png";
import "../DashboardPages.scss";

const Profile = () => {
    const user = useRecoilValue(gUserState);

    return (
        <div className='main-dashboard-pages'>
            <DashboardHeader />
            <DashboardAside />
            <div className='dash-page-container profile-card'>
                <Link to="/app/dashboard/profile/edit-data"> <img className='profileEditIcon' src={EditIcon} alt='edit data' /> </Link>

                <h1>My profile</h1>
                <img src={user.profileImageUrl} alt="" className="profile-picture" />
                <div className='user-data'>
                    <h2 className="name">Name: {user.name}</h2>
                    <h2 className="name">Email: {user.email}</h2>
                </div>
            </div>
        </div>);
};

export default Profile;