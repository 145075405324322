import React, { ReactNode, useEffect, useRef } from 'react';
import './ModalAside.scss';

interface ModalAsideProps {
    children: ReactNode;
    onClose: () => void;
}

const ModalAside: React.FC<ModalAsideProps> = ({ children, onClose }) => {
    const modalAsideRef = useRef<HTMLDivElement>(null);

    const closeModal = (event: MouseEvent) => {
        if (modalAsideRef.current && !modalAsideRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', closeModal);
        return () => {
            document.removeEventListener('mousedown', closeModal);
        };
    },);

    return (
        <div className="modalAside" ref={modalAsideRef}>
            <div className="modalAside-content">
                {children}
            </div>
        </div>
    );
};

export default ModalAside;