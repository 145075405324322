
import { Link } from "react-router-dom"
export const HomePageContent = () => {
    return (
        <>
            <main className='homepage-main'>
                <div className="content">
                    <div className="div__slogan">
                        <h2>We use AI to solve the problem of humans forgetting things!</h2>
                        <p>
                            Welcome to ZenMinder! Your day will become much lighter, efficient
                            and productive. Allow yourself this innovative experience of planning and executing
                            your day through artificial intelligence!
                        </p>
                        {/* <MktVideo /> */}
                        <div className="div__button-main">
                            {/* Use Link component for client-side routing */}
                            <Link to="/auth/sign-up" className="get-zenminder-btn-2" id="button__register">
                                Get ZenMinder for Free
                            </Link>
                        </div>
                    </div>

                </div>

            </main>
        </>
    )
}