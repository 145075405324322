import { IReminderCreateRequestDTO } from "dtos/task.dto";
import { IReminder } from "models/task.model";

class RemindersService {
    async createReminder(token: string, newTask: IReminder): Promise<IReminder> {
        const url = 'https://us-central1-zenminder-2e6d5.cloudfunctions.net/reminders';
        const requestBody: IReminderCreateRequestDTO = newTask;
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody)
        };
        const response = await fetch(url, options);
        const createdTask: IReminder = await response.json();
        return createdTask;
    }

    async getRemindersList(token: string): Promise<IReminder[]> {
        const url = 'https://us-central1-zenminder-2e6d5.cloudfunctions.net/reminders';
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        const response = await fetch(url, options);
        const remindersList: IReminder[] = await response.json();
        return remindersList;
    }
    async updateReminder(token: string, taskId: string, taskData: IReminder): Promise<IReminder> {
        const url = `https://us-central1-zenminder-2e6d5.cloudfunctions.net/reminders/${taskId}`;
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(taskData),
        };
        const response = await fetch(url, options);
        return response.json();
    }

    async deleteReminder(token: string, taskId: string): Promise<void> {
        const url = `https://us-central1-zenminder-2e6d5.cloudfunctions.net/reminders/?reminderID=${taskId}`;
        const options = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                console.error(`Failed to delete reminder with response status: ${response.status}`);
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error deleting reminder:", error);
            throw new Error("The task could not be deleted. Please try again later.");
        }
    }

}
export const remindersService = new RemindersService();