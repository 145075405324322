import { Link } from 'react-router-dom';
import DiamondIcon from "../../../../assets/icons/Diamond.png";
import DashboardIcon from "../../../../assets/icons/dashboard.png";
import MarketIcon from "../../../../assets/icons/market.png";
import MyProfileIcon from "../../../../assets/icons/my-profile.png";
import PaymentsIcon from "../../../../assets/icons/payments.png";
import VerifyIcon from "../../../../assets/icons/verify.png";
import "./DashboardAside.scss";

const DashboardAside = () => {
    return (
        <aside className="DashboardAside">
            <div className="div__zen-diamonds">
                <img src={DiamondIcon} alt='zen-diamond' className='img__zen-diamond' />
                <h2 className="h2__zen-diamonds-counter">10</h2>
            </div>
            <div className="Links__dashboard-aside ">
                <Link to="/app/dashboard"><img src={DashboardIcon} alt='dashboard' className='aside-icon' /> Dashboard</Link>
                <Link to="/app/dashboard/profile"><img src={MyProfileIcon} alt='profile' className='aside-icon' /> My Profile</Link>
                <Link to="/app/dashboard/verify-phone-number"> <img src={VerifyIcon} alt='Verify phone number' className='aside-icon' /> Verify your phone</Link>
                <Link to="/app/dashboard/profile/payment"> <img src={PaymentsIcon} alt='payments' className='aside-icon' /> Payments</Link>
                <Link to="/app/dashboard/market"> <img src={MarketIcon} alt='market' className='aside-icon' /> Market</Link>
            </div>
        </aside>
    );
};


export { DashboardAside };

