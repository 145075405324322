import { EGlobalStateKeys } from "models/global-store";
import { atom } from "recoil";

export interface IUser {
    uid: string,
    token: string,
    displayName: string,
    photoURL: string,
    email: string
    name: string,
    surname: string,
    whatsapp: string,
    profileImageUrl: string,
    numberOfDiamonds: number,
}

export function isValidUser(user: IUser) {
    return user.email !== '' && user.name !== '' && user.surname !== '' && user.whatsapp !== '';
}

export const gEmptyUser: IUser = {
    displayName: '',
    photoURL: '',
    email: '',
    token: '',
    uid: '',
    name: '',
    surname: '',
    whatsapp: '',
    profileImageUrl: '',
    numberOfDiamonds: 0
}

export const gUserState = atom<IUser>({
    key: EGlobalStateKeys.user,
    default: gEmptyUser,
});