import { Alert, Snackbar } from "@mui/material";
import googleIcon from "assets/google.svg";
import logo from 'assets/images/logo.png';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authService } from 'services/AuthService';
import './SignUpPage.scss';




const SignUpPage = () => {
    // const [name, setName] = useState('');
    // const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const user = await authService.signUp(email, password);
            console.log('User created successfully:', user);
            setSnackbarMessage('Registration completed successfully!');
            setOpenSnackbar(true);
            setTimeout(() => navigate('/auth/login'), 3000);
        } catch (error) {
            console.error("Error during sign up: ", error);
            // Handle sign up error
        }
    };

    const signUpWithGoogleAccount = async () => {
        const result = await authService.signUpWithGoogleProvider();
        console.log({ signUpWithGoogleAccount: result });
    }
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();

    return (
        <div className='div__signup'>
            <header className="header__signUp">
                <div className="div__logo-and-name">
                    <Link className="button__home" to="/">
                        <img src={logo} className="img__logo--signup" alt="Logo Zenminder" />
                    </Link>
                    <h2>ZenMinder</h2>
                </div>
            </header>
            <section className="container">
                <h1 className="h1__signUp">Get Started</h1>
                <article className="article__signUp">Create your free ZenMinder Account to continue</article>
                <form onSubmit={handleSignUp}>
                    <div className="input__container">
                        <div>
                            <input type="text" id="name" name="name" placeholder="Your first name" required />
                            <input type="text" id="surname" name="surname" placeholder="Your last Name" required />
                        </div>
                        <input type="email" id="email" name="email" placeholder="Type your E-mail Address" required onChange={(e) => setEmail(e.target.value)} />
                        <input title="Enter a Password" type="password" id="password" name="password" placeholder="Enter a Password" required onChange={(e) => setPassword(e.target.value)} />
                        <label htmlFor="acceptTermsCheckbox">
                            <input id="acceptTermsCheckbox" className="input__checkbox" type="checkbox" required />
                            <p>I have read and I accept the ZenMinder's Terms of Use.</p>
                        </label>

                        <button type="submit" className="submit-btn">Continue</button>
                    </div>
                </form>

            </section>
            {/* <div className="container__or">OR
            </div> */}
            <section className="container__alternative">
                <div className="div__already-have-account">
                    <p>Already have an account? </p> <Link to="/auth/login">Log in</Link>
                </div>
                <p className="p_divisor">---------------------------------------</p>

                <button className="btn-google" onClick={signUpWithGoogleAccount}>
                    <img src={googleIcon} alt="google icon" className="icon__signup" />
                    Sign up with Google
                </button>


            </section>
            <p className="p_divisor">---------------------------------------</p>

            <footer>

                <a href="/terms-of-use">Terms of use</a>
                <p>|</p>
                <a href="/privacy">Privacy Policy</a>
            </footer>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SignUpPage;