import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ModalVerification.scss';

interface ModalVerificationProps {
    isOpen: boolean;
    onClose: () => void;
    verifyPhoneNumber: (verificationCode: string) => Promise<void>;
}

export const ModalVerification: React.FC<ModalVerificationProps> = ({ isOpen, onClose, verifyPhoneNumber }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [verificationCode, setVerificationCode] = useState('');

    const closeModal = useCallback((event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            onClose();
        }
    }, [onClose]);

    useEffect(() => {
        document.addEventListener('mousedown', closeModal);
        return () => {
            document.removeEventListener('mousedown', closeModal);
        };
    }, [closeModal]);

    if (!isOpen) return null;

    const handleVerificationSubmit = async () => {
        await verifyPhoneNumber(verificationCode);
    };

    return (
        <div className="modalVerification" ref={modalRef} style={{ display: isOpen ? 'flex' : 'none' }}>
            <div className="modalVerification-content">
                <input
                    className='input-verification'
                    type="text"
                    placeholder="Insert the received code here"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                />
                <button type="submit" onClick={handleVerificationSubmit}>Confirm</button>
                <button className="resendCodeButton" type="button">
                    If you didn't receive the code, check the number and click here
                </button>
            </div>
        </div>
    );
}

export default ModalVerification;
