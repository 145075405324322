import { diamondsService } from 'services/DiamondsService';
import "./Market.scss";

const Market = () => {
    const diamonds = diamondsService.getDiamonds();
    const prizes = diamondsService.getPrizes();

    return (
        <div className='market__main'>
            <h1 className='market__title'>Diamonds Market</h1>
            <div className="market__container">
                {diamonds.map((diamond, index) => (
                    <div className="market__container__card" key={index}>
                        <img src={diamond.img} alt="Diamond" className="market__container__card__image" />
                        <div className="market__container__card__info">
                            <h2>{diamond.Item}</h2>
                            <p>Price: ${diamond.Price}</p>
                            <button className="market__container__card__info__buy-btn">Buy</button>
                        </div>
                    </div>
                ))}
            </div>
            <h2 className='market__title'>Prizes</h2>
            <div className="market__container">
                {prizes.map((prize, index) => (
                    <div className="market__container__card" key={index}>
                        <img src={prize.img} alt="Prize" className="market__container__card__image" />
                        <div className="market__container__card__info">
                            <h2>{prize.Description}</h2>
                            <p>Diamonds Needed: {prize.DiamondsNeeded}</p>
                            <button className="market__container__card__info__buy-btn">Exchange</button>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Market;
