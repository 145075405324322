import { EFrontendErrorID } from 'models/error-descriptor.model';
import React from 'react';
import ReactDOM from 'react-dom';
import AlertModal from '../components/AlertModal/AlertModal';


interface IModalParams {
    errorID?: EFrontendErrorID,
    onClose?: () => void
}

class ModalService {
    private openModal(message: string, params: IModalParams) {
        const modalDiv = document.createElement('div');
        document.body.appendChild(modalDiv);

        ReactDOM.render(
            <AlertModal message={message} onClose={() => {
                ReactDOM.unmountComponentAtNode(modalDiv);
                document.body.removeChild(modalDiv);
                if (params.onClose) {
                    params.onClose();
                }
            }} />,
            modalDiv
        );
    }


    openError(errorID: EFrontendErrorID, message: string) {
        this.openModal(message, { errorID, onClose: () => console.log('Error modal closed') });
    }

    openWarning(message: string) {
        this.openModal(message, { onClose: () => console.log('Warning modal closed') });
    }

    openInfo(message: string) {
        this.openModal(message, { onClose: () => console.log('Info modal closed') });
    }

    openComponentIntoDialog(component: React.ReactElement, onCloseAction: () => void) {
        const modalDiv = document.createElement('div');
        document.body.appendChild(modalDiv);

        ReactDOM.render(
            React.cloneElement(component, {
                onClose: () => {
                    ReactDOM.unmountComponentAtNode(modalDiv);
                    document.body.removeChild(modalDiv);
                    if (onCloseAction) {
                        onCloseAction();
                    }
                }
            }),
            modalDiv
        );
    }
}

export const modalService = new ModalService();
