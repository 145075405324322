export interface Diamond {
    Item: string;
    Quantity: number;
    Price: number;
    img: string;
}

export interface Prize {
    Description: string;
    DiamondsNeeded: number;
    img: string;

}

class DiamondsService {
    mockDiamonds: Diamond[];
    mockPrizes: Prize[];

    constructor() {
        this.mockDiamonds = [
            { Item: "One diamond", Quantity: 1, Price: 10, img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuyocA5aBVJ3cnKkGs9JYajnn6f1n9BGa11xw_5yDspKghPDhX6GzmjGxpl1ypTC7hswY&usqp=CAU" },
            { Item: "10 diamonds", Quantity: 10, Price: 90, img: "https://previews.123rf.com/images/emojiimage/emojiimage1802/emojiimage180200340/95769637-large-brown-bag-full-of-blue-shiny-diamonds.jpg" },
            { Item: "50 diamonds", Quantity: 50, Price: 400, img: "https://maniafreefire.com.br/wp-content/uploads/2020/02/diamantes-gratis.png" },
            { Item: "100 diamonds", Quantity: 100, Price: 700, img: "https://conteudo.imguol.com.br/c/entretenimento/34/2021/07/02/free-fire-diamantes-1625233029621_v2_900x506.jpg" }
        ];

        this.mockPrizes = [
            { Description: "1 month free", DiamondsNeeded: 10, img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLMrIUYCw1gDYW7AmLpFj71n9Ft1wF_1Z4Cs6GyX25UA&s" },
            { Description: "3 months free", DiamondsNeeded: 20, img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBddcT-ixC16nWUPB9V3A3Rejl5zfnfNz3kSp96c_69Q&s" },
            { Description: "1 year free", DiamondsNeeded: 50, img: "https://png.pngtree.com/png-vector/20220417/ourmid/pngtree-1-year-warranty-badge-png-image_4546794.png" },
        ];
    }

    getDiamonds(): Diamond[] {
        return this.mockDiamonds;
    }

    getPrizes(): Prize[] {
        return this.mockPrizes;
    }
}

export const diamondsService = new DiamondsService();
