import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { EGenerationStyleCategory, IReminder } from 'models/task.model';
import React, { useEffect, useState } from 'react';
import '../../../../styles/base/Colors.scss';
import '../../../../styles/base/typography.scss';
import { handleInputChange } from '../../../../utils/TaskModalfunctions';
import './TasksModal.scss';

interface TasksModalProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTasks: React.Dispatch<React.SetStateAction<IReminder[]>>;
    task: IReminder;
    setTask: React.Dispatch<React.SetStateAction<IReminder>>;
    isEditMode: boolean;
    tasks: IReminder[];
    onSubmit: () => void;
}

const TasksModal: React.FC<TasksModalProps> = ({
    showModal,
    setShowModal,
    task,
    setTask,
    isEditMode,
    onSubmit: propsOnSubmit
}) => {
    const [generationStyle, setGenerationStyle] = useState<EGenerationStyleCategory | ''>('');

    useEffect(() => {
        if (!isEditMode && !task.dueDate) {
            const today = dayjs().format('YYYY-MM-DDTHH:mm');
            setTask({ ...task, dueDate: today });
        }
        if (isEditMode && task.categories && task.categories.length > 0) {
            setGenerationStyle(task.categories[0]);
        }
    }, [isEditMode, task, setTask]);

    const onSubmit = () => {
        const updatedTask = {
            ...task,
            categories: generationStyle ? [generationStyle] : [],
        };
        setTask(updatedTask);
        propsOnSubmit();
    };

    return (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
            <Box p={2} bgcolor="white" boxShadow={2} margin="auto" position="absolute" top="50%" left="50%" style={{ transform: 'translate(-50%, -50%)', width: 350, padding: "30px" }}>
                <Typography style={{ fontWeight: 600, fontSize: '2.2rem' }} className='modalTitle' variant="h6" marginBottom={2}>
                    {isEditMode ? 'Edit Task' : 'Add New Task'}
                </Typography>

                <h2 style={{ fontWeight: 600, fontSize: '1.3rem', marginTop: '5px' }}>
                    {isEditMode ? 'Edit your reminder here:' : 'Insert your reminder here:'}
                </h2>
                <TextField
                    label="Task"
                    name="title"
                    value={task.title}
                    onChange={(event) => handleInputChange(event, task, setTask)}
                    fullWidth
                    margin="normal"
                />
                <select
                    value={generationStyle}
                    onChange={(e) => setGenerationStyle(e.target.value as EGenerationStyleCategory)}
                    style={{ width: '100%', height: '56px', marginTop: '16px', marginBottom: '8px', padding: '10px 15px' }}
                >
                    <option value="">Select Style</option>
                    <option value="humor">Humor</option>
                    <option value="assertive">Assertive</option>
                </select>
                <h2 style={{ fontWeight: 600, fontSize: '1.3rem', marginTop: '20px' }}>
                    Select the date and time of your reminder here:
                </h2>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TextField
                        type="datetime-local"
                        name="dueDate"
                        value={task.dueDate}
                        onChange={(event) => handleInputChange(event, task, setTask)}
                        fullWidth
                        margin="normal"
                    />
                </LocalizationProvider>

                <Button
                    style={{ marginTop: '20px' }}
                    onClick={onSubmit}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    {isEditMode ? 'Update Task' : 'Add Task'}
                </Button>
            </Box>
        </Modal>
    );
};

export default TasksModal;
