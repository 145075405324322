import { gUserState } from 'models/user.model';
import { DashboardAside } from 'pages/Dashboard/components/DashboardAside/DashboardAside';
import DashboardHeader from 'pages/Dashboard/components/DashboardHeader/DashboardHeader';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import './EditUserData.scss';

const EditUserData = () => {
    const [user, setUser] = useRecoilState(gUserState);
    const [tempImage, setTempImage] = useState(user.profileImageUrl);
    const navigate = useNavigate();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const imageUrl = URL.createObjectURL(file);
            setTempImage(imageUrl);
        }
    };

    const handleCancel = () => {
        navigate('/app/dashboard');
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const updatedUser = {
            ...user,
            profileImageUrl: tempImage,
        };
        setUser(updatedUser);
        navigate('/app/dashboard');
    };

    return (
        <div className='main-div'>
            <DashboardHeader />
            <DashboardAside />
            <h1 className="updateYourData">Update your Data</h1>
            <div className="edit-data">
                <div className="profile-section">
                    <img src={tempImage} alt="Profile" className="profile-picture" />
                    <input type="file" id="file" className="file-input" onChange={handleImageChange} />
                </div>
                <form className="edit-form" onSubmit={handleSubmit}>
                    <input type="text" placeholder="Name" name="name" />
                    <input type="email" placeholder="Email" name="email" />
                    <input type="password" placeholder="Password" name="password" />                    <button type="submit" className="submit-button">Update data</button>
                    <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button>
                </form>
            </div>
        </div>
    );
};

export default EditUserData;
