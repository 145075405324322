import logo from 'assets/images/logo.png';
import userIcon from 'assets/user.png';
import { gUserState } from 'models/user.model';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import hamburgerIcon from "../../assets/images/hamburger.svg";
import './Header.scss';

const Header = () => {
    const user = useRecoilValue(gUserState);
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const isLoggedIn = user && user.email;

    return (
        <header className='container__header'>
            <div className="div__logo-and-name">
                <Link className="button__home" to="/">
                    <img src={logo} className="img__logo--signup" alt="Logo Zenminder" />
                    <h2>ZenMinder</h2>
                </Link>

            </div>

            <nav>
                {isLoggedIn ? (
                    <div className="user-info">
                        <Link to="/app/dashboard"><img src={user.profileImageUrl || userIcon} className="userIcon" alt="user" />
                            <h2 className='h2Name'>{user.displayName}</h2></Link>
                    </div>
                ) : (
                    <>
                        <nav className='nav__header'>
                            <Link className="nav__button" to="/auth/login">Login</Link>
                        </nav>
                        <Link to="/auth/sign-up" className="get-zenminder-btn-1" id="button__register">Sign up</Link>
                    </>
                )}

                <button className="button__hamburger" onClick={toggleMenu}>
                    <img src={hamburgerIcon} alt="Menu Hamburger Icon" />
                </button>

                <div className="menu" id="menu" style={{ display: menuVisible ? 'block' : 'none' }}>
                    {isLoggedIn ? (
                        <Link to="/app/dashboard">Dashboard</Link>
                    ) : (
                        <Link to="/sign-in">Sign in</Link>
                    )}
                    <p>_____________________</p>
                    <Link to="/about-us">Who are us?</Link>
                    <p>_____________________</p>
                    <Link to="/mission">Our mission</Link>
                    <p>_____________________</p>
                    <Link to="/privacy">Privacy Policy</Link>
                    <p>_____________________</p>
                    <Link to="/terms-of-use">Terms of use</Link>
                </div>
            </nav>
        </header>
    );
};

export default Header;
