// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, User, UserCredential, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { EFrontendErrorID } from "models/error-descriptor.model";
import { IResult } from "models/message-status.model";
import { IUser, gEmptyUser } from "models/user.model";
import { modalService } from "services/ModalService";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD6M6T0KWF3w_HIuRdmCJwE--8x4Lo92FM",
    authDomain: "auth.zenminder.cognuscraft.com", //process.env.NODE_ENV === 'production' ? "zenminder.cognuscraft.com" : "localhost:5000",
    projectId: "zenminder-2e6d5",
    storageBucket: "zenminder-2e6d5.appspot.com",
    messagingSenderId: "488507901406",
    appId: "1:488507901406:web:1ea4a6a1afcb758c6cc94f",
    measurementId: "G-QQ868014MF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);


class AuthService {
    onPageLoad(setUserState: Function): Promise<{ user: any, unsubscribe: any }> {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                try {
                    if (user) {
                        const token = await user.getIdToken()
                        const userData = {
                            displayName: user.displayName as string,
                            photoURL: user.photoURL as string,
                            email: user.email as string,
                            token,
                            uid: user.uid,
                            name: user.displayName as string || '',
                            surname: '',
                            whatsapp: '',
                            profileImageUrl: user.photoURL as string || ''
                        }
                        setUserState(userData);
                        resolve({ user, unsubscribe });
                    } else {
                        setUserState(null);
                        resolve({ user: null, unsubscribe });
                    }
                } catch (error) {
                    reject(error);
                }
            });
        });
    }

    async signUpWithGoogleProvider(): Promise<IResult> {
        try {
            const user = await this.getUserFromGoogleProvider()
            if (!user.emailVerified) {
                await sendEmailVerification(user);
                modalService.openInfo("Account created. Please check your email to verify your address!");
            }
            return { success: true };
        } catch (error) {
            this.showError(EFrontendErrorID.errorWhenTryingToSignUpWithGoogleProvider, "Error when trying to signup in with Google!", error);
            return { success: false };
        }
    }

    async loginWithGoogleProvider(setUserState: Function): Promise<IResult<IUser>> {
        try {
            console.log('Getting user from Google Provider');
            const user = await this.getUserFromGoogleProvider()
            console.log('User from Google:', user);
            const token = await user.getIdToken()
            console.log('Token from Google login:', token);
            const userData = {
                displayName: user.displayName as string,
                photoURL: user.photoURL as string,
                email: user.email as string,
                token,
                uid: user.uid,
                name: user.displayName as string || '',
                surname: '',
                whatsapp: '',
                profileImageUrl: user.photoURL as string || '',
                numberOfDiamonds: 0,
            }
            setUserState(userData)
            console.log(userData);
            return {
                success: true, data: userData
            };
        } catch (error) {
            this.showError(EFrontendErrorID.errorWhenTryingToLoginWithGoogleProvider, "Error logging in with Google!", error);
            setUserState(gEmptyUser)
            return { success: false };
        }
    }

    async signUp(email: string, password: string): Promise<IResult> {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(userCredential.user);

            modalService.openInfo("Success, please check your email to verify your address!");
            return { success: true };
        } catch (error) {
            this.showError(EFrontendErrorID.errorWhenTryingToSignUpWithEmail, "Error signing up!", error)

            return { success: false };
        }
    }

    async login(email: string, password: string, setUserState: Function): Promise<IResult<IUser>> {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log({ userCredential });
            const user = userCredential.user
            if (!user.emailVerified) {
                modalService.openInfo("Please check your email to verify your address!");
                return { success: false };
            }
            const token = await user.getIdToken()
            const userData = {
                displayName: user.displayName as string,
                photoURL: user.photoURL as string,
                email: user.email as string,
                token,
                uid: user.uid,
                name: user.displayName as string || '',
                surname: '',
                whatsapp: '',
                profileImageUrl: user.photoURL as string || '',
                numberOfDiamonds: 0,
            }
            setUserState(userData)
            return {
                success: true,
                data: userData
            };
        } catch (error) {
            this.showError(EFrontendErrorID.errorWhenTryingToLoginWithEmail, "Error logging in!", error)
            setUserState(gEmptyUser)
            return { success: false };
        }
    }

    async logout() {
        try {
            await signOut(auth);
        } catch (error) {
            this.showError(EFrontendErrorID.errorWhenTryingToLogoutWithEmail, "Error logging out!", error)
            throw error
        }
    }

    showError(errorID: EFrontendErrorID, msg: string, error: any) {
        console.error(msg, error);
        modalService.openError(errorID, msg);
    };

    private async getUserFromGoogleProvider() {
        const provider = new GoogleAuthProvider();
        const userCredential: UserCredential = await signInWithPopup(auth, provider);
        const user: User = userCredential.user;
        return user
    }
}

export const authService = new AuthService();