import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <div className='div__mother'>
                <div className='div__btn-footer'>
                    <Link className="btn__footer" to="/about-us">Who are us?</Link>
                    <Link className="btn__footer" to="/mission">Our mission</Link>
                    <Link className="btn__footer" to="/privacy">Privacy Policy</Link>
                    <Link className="btn__footer" to="/terms-of-use">Terms of use</Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;