import { DashboardAside } from 'pages/Dashboard/components/DashboardAside/DashboardAside';
import DashboardHeader from 'pages/Dashboard/components/DashboardHeader/DashboardHeader';
import TaskList from 'pages/Dashboard/components/TaskList/TaskList';
import EditUserData from 'pages/Dashboard/pages/EditData/EditUserData';
import Market from 'pages/Dashboard/pages/Market/Market';
import PaymentDetails from 'pages/Dashboard/pages/PaymentDetails/PaymentDetails';
import Profile from 'pages/Dashboard/pages/Profile/Profile';
import VerifyPhoneNumber from 'pages/Dashboard/pages/VerifyPhoneNumber/VerifyPhoneNumber';
import { Route, Routes } from 'react-router-dom';
import './Dashboard.scss';

const Dashboard = () => {
    return (
        <div>
            <div className="dashboard">
                <DashboardHeader />
                <DashboardAside />
                <section className="dashboard-content">
                    <Routes>
                        <Route path="/" element={<TaskList />} />
                        {/* <Route path="/task-list" element={<TaskList />} /> */}
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/profile/edit-data" element={<EditUserData />} />
                        <Route path="/profile/payment" element={<PaymentDetails />} />
                        <Route path="/verify-phone-number" element={<VerifyPhoneNumber />} />
                        <Route path="/market" element={<Market />} />
                    </Routes>
                </section>
            </div>
        </div>
    );
};

export default Dashboard;
