import Dashboard from 'pages/Dashboard/Dashboard';

import InitApp from 'AppInit';
import HomePage from 'pages/Home/HomePage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.scss';
import AboutUs from './pages/AboutUs/AboutUs';
import LoginPage from './pages/Auth/LoginPage/LoginPage';
import SignUpPage from './pages/Auth/SignUpPage/SignUpPage';
import Mission from './pages/Mission/Mission';
import Privacy from './pages/Privacy/Privacy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';

const App: React.FC = () => {
    return (
        <RecoilRoot>
            <InitApp />
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/mission" element={<Mission />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />

                    <Route path="/auth/login" element={<LoginPage />} />
                    <Route path="/auth/sign-up" element={<SignUpPage />} />

                    <Route path="/app/dashboard/*" element={<Dashboard />} />
                </Routes>
            </Router>
        </RecoilRoot>
    );
};

export default App;

