import { IPhoneNumberVerified } from 'dtos/phone-number-verification.dto';
import { Phone } from 'models/phone.models';
import React, { useEffect, useState } from 'react';
import DeleteIcon from "../../../../assets/icons/delete.png";
import "../../pages/DashboardPages.scss";

interface ChildComponentProps {
    verifiedPhones: IPhoneNumberVerified[];
}

const VerifiedPhoneList: React.FC<ChildComponentProps> = ({ verifiedPhones }) => {
    const [phones, setPhones] = useState<Phone[]>([]);

    const convertToPhoneType = (verifiedPhones: IPhoneNumberVerified[]): Phone[] => {
        return verifiedPhones.map((verifiedPhone, index) => ({
            title: `Phone ${index + 1} (${verifiedPhone.country})`,
            number: verifiedPhone.number,
            id: verifiedPhone.userID
        }));
    };

    const deletePhone = async (phoneId: string) => {
        const updatedPhones = phones.filter(phone => phone.id !== phoneId);
        setPhones(updatedPhones);
    };

    useEffect(() => {
        if (verifiedPhones) {
            const phoneList = convertToPhoneType(verifiedPhones);
            setPhones(phoneList);
        }
    }, [verifiedPhones]);

    return (
        <div className='verifiedPhones'>
            <h1>Verified Phone Numbers</h1>
            {phones.length > 0 ? (
                <table className='phoneTable'>
                    <tbody>
                        {phones.map((phone, index) => (
                            <tr key={index}>
                                <td>{phone.title}</td>
                                <td>{phone.number}</td>
                                <td>
                                    <button className="deletePhone" onClick={() => deletePhone(phone.id)}>
                                        <img src={DeleteIcon} className="phone-nav-icon" alt="Delete" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No phones verified</p>
            )}
        </div>
    );
};

export default VerifiedPhoneList;
