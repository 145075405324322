import { EGenerationStyleCategory, TISODate } from 'models/task.model';
import React, { useEffect, useRef, useState } from 'react';
import WhatsappArrow from "../../../../assets/icons/WhatsappArrow.svg";
import DeleteIcon from "../../../../assets/icons/delete.png";
import DropdownIcon from "../../../../assets/icons/dropdown.svg";
import EditIcon from "../../../../assets/icons/edit.png";
import "./WhatsappTemplateItem.scss";

interface WhatsappTemplateItemProps {
    imageURL: string;
    title: string;
    dueDate: TISODate;
    categories?: EGenerationStyleCategory[];
    onEdit: () => void;
    onDelete: () => void;
}

export const WhatsappTemplateItem: React.FC<WhatsappTemplateItemProps> = ({
    imageURL, title, dueDate, categories, onEdit, onDelete
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const formattedDate = new Date(dueDate).toLocaleDateString('en-US');
    const formattedTime = new Date(dueDate).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    return (
        <section className="card__template-whatsapp">
            <div className="header__template-whatsapp">
                <div className='div__overlay-and-dropdown'>
                    <div className="category__overlay">
                        {categories && categories.length > 0 ? categories[0] : 'No Style'}
                    </div>
                    <div ref={dropdownRef}>
                        <img src={DropdownIcon} alt="Dropdown" onClick={toggleDropdown} className="dropdown-icon" />
                        {dropdownOpen && (
                            <div className="dropdown-content">
                                <button onClick={onEdit}><img src={EditIcon} alt="Edit" /></button>
                                <button onClick={onDelete}><img src={DeleteIcon} alt="Delete" /></button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="header__template-whatsapp">
                    {/* <img className="img__template-whatsapp" src={imageURL} alt="Task" /> */}
                    <img className="img__template-whatsapp" src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*ktEC3POfrBMnHyDv3y-Xag.jpeg" alt="IA img" />
                </div>
            </div>
            <div className="main__template-whatsapp">
                <p className="text__template-whatsapp">{title}</p>
                <p className="time__template-whatsapp">{`${formattedDate} at ${formattedTime}`}</p>
            </div>
            <div className="footer__template-whatsapp">
                <img className="arrow-icon__template-whatsapp" src={WhatsappArrow} alt="Arrow" />
                <p className="button__template-whatsapp">Completed</p>
            </div>
        </section>
    );
};
