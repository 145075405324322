import { gUserState } from 'models/user.model';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authService } from 'services/AuthService';
import hamburgerIcon from '../../../../assets/images/hamburger.svg';
import logo from "../../../../assets/images/logo.png";
import userIcon from "../../../../assets/user.png";
import Modal from '../Modal/Modal';
import ModalAside from '../ModalAside/ModalAside';
import './DashboardHeader.scss';

const DashboardHeader = () => {
    const user = useRecoilValue(gUserState);
    const [showModal, setShowModal] = useState(false);
    const [showModalAside, setShowModalAside] = useState(false);
    const navigate = useNavigate();

    const handleLogout = async () => {
        await authService.logout();
        navigate('/');
    };

    return (
        <header className='container__dashboardHeader'>
            <div className="headerLeft">
                <button className="hamburger" onClick={() => setShowModalAside(true)}>
                    <img src={hamburgerIcon} className="hamburgerIcon" alt="hamburger" />
                </button>

                {showModalAside && (
                    <ModalAside onClose={() => setShowModalAside(false)}>
                        <Link to="/app/dashboard" className='modalAside-item'>Dashboard</Link>
                        <Link to="/app/dashboard/profile" className='modalAside-item'>My profile</Link>
                        <Link to="/app/dashboard/verify-phone-number" className='modalAside-item'>Verify your phone</Link>
                        <Link to="/app/dashboard/profile/payment" className='modalAside-item'>Payments</Link>
                        <Link to="/app/dashboard/market" className='modalAside-item'>Market</Link>
                    </ModalAside>
                )}

                <div className="zDashLogoAndName">
                    <Link className="button__dashboard-home" to="/">
                        <img src={logo} className="img__logo--dashboard" alt="Logo Zenminder" />
                        <h2>ZenMinder</h2>
                    </Link>
                </div>
            </div>

            <div className="zDashLogoAndName">
                <button className="buttonUser-dashboard" onClick={() => setShowModal(true)}>
                    <img src={user.profileImageUrl || userIcon} className="userIcon" alt="user" />
                    {showModal && (
                        <Modal onClose={() => setShowModal(false)}>
                            <Link to="/app/dashboard/profile" className="modal-item">Profile</Link>
                            <button onClick={handleLogout} className="modal-item">Logout</button> {/* Alterado para botão */}
                        </Modal>
                    )}
                    <h2 className='h2Name'>{user.displayName}</h2>
                </button>
            </div>
        </header>
    );
};

export default DashboardHeader;
