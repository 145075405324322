import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import googleIcon from 'assets/google.svg';
import logo from 'assets/images/logo.png';
import { gUserState } from 'models/user.model';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { authService } from 'services/AuthService';
import './LoginPage.scss';

// Snackbar Alert component
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const setUserState = useSetRecoilState(gUserState);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const loginWithEmailAndPass = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('Attempting login with email and password');
        const loginResult = await authService.login(email, password, setUserState);
        console.log('Login result:', loginResult);
        if (loginResult.success) {
            navigate('/app/dashboard', { state: { user: loginResult.data! } });
        } else {
            setSnackbarMessage('Error logging in!');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const loginWithGoogle = async () => {
        console.log('Attempting login with Google');
        const loginResult = await authService.loginWithGoogleProvider(setUserState);
        console.log('Login result with Google:', loginResult);
        if (loginResult.success) {
            navigate('/app/dashboard', { state: { user: loginResult.data! } });
        } else {
            setSnackbarMessage('Error logging in with Google!');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    return (
        <div className='div__signin'>
            <header className="header__login">
                <div className="div__logo-and-name">
                    <Link className="button__home" to="/">
                        <img src={logo} className="img__logo--signup" alt="Logo Zenminder" />
                    </Link>
                    <h2>ZenMinder</h2>
                </div>
            </header>
            <section className="container-login">
                <h1 className="h1__login">Welcome back</h1>
                <form onSubmit={loginWithEmailAndPass}>
                    <div className="input__container" id="inputContainer">
                        <input type="email" id="email" name="email" placeholder="Email Address" required onChange={e => setEmail(e.target.value)} />
                        <input type="password" id="password" name="password" placeholder="Password" required onChange={e => setPassword(e.target.value)} />
                        <button type="submit" className="submit-btn">Continue</button>
                    </div>
                </form>
                <div className="div__already-have-account">
                    <p>Don't have an account? </p> <Link to="/auth/sign-up">Sign Up</Link>
                </div>
            </section>
            <p className="p_divisor">---------------------------------------</p>
            <section className="container__alternative">
                <button className="btn-google" onClick={loginWithGoogle}>
                    <img src={googleIcon} alt="google icon" className="icon__signup" />
                    Login with Google
                </button>
            </section>
            <p className="p_divisor">---------------------------------------</p>
            <footer>
                <a href="../terms-of-use/terms-of-use.html">Terms of use</a>
                <p>|</p>
                <a href="../Privacy/privacy.html">Privacy Policy</a>
            </footer>
            {/* Snackbar for displaying messages */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default LoginPage;
