import AboutUsIcon from 'assets/aboutUS.webp';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import "../Pages.scss";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <main className="text__main">
        <section className="text__section">
          <h1>Who are us?</h1>
          <p>Zenminder stands at the forefront of productivity solutions, harnessing the power of artificial
            intelligence to redefine how tasks are remembered and accomplished.</p>
          <p>Our signature online checklist,
            designed with precision and insight, ensures that you are reminded of essential tasks exactly when you
            need it, right in your most-used application.</p>
          <p>Driven by a mission to ensure that important tasks are
            never overlooked, we uphold key business principles that guide our journey: </p>
          <p>Innovation in every solution
            we craft, unwavering Trust in our technology and with our users, relentless Hard Work to continually
            elevate our offerings, Teamwork that amplifies our collective strengths, and an enduring commitment to
            Valuing Our Team, the very heart of our success.</p>
          <p>With Zenminder, you're not just adopting a tool; you're
            joining a community dedicated to enhancing productivity in the modern world.</p>
        </section>
        <p><img src={AboutUsIcon} alt="Artistic representation of us." className="img__background" />
        </p>
      </main>
      <Footer />
    </div>
  );

};

export default AboutUs;
