import { IReminder } from 'models/task.model';
import React from 'react';

export const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    task: IReminder,
    setTask: React.Dispatch<React.SetStateAction<IReminder>>
) => {
    const { name, value } = event.target as HTMLInputElement;
    setTask({ ...task, [name]: value });
};

export const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setTask: React.Dispatch<React.SetStateAction<IReminder>>
) => {
};

export const addTask = async (
    tasks: IReminder[],
    setTasks: React.Dispatch<React.SetStateAction<IReminder[]>>,
    newTask: IReminder,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
) => {

    const updatedTasks = [...tasks, { ...newTask, id: Date.now().toString() }];
    setTasks(updatedTasks);
    setShowModal(false);
};

// export const editTask = async (
//     taskId: string,
//     updatedTaskData: IReminder,
//     setTasks: React.Dispatch<React.SetStateAction<IReminder[]>>
// ) => {
//     let tasks = await remindersService.getRemindersList();
//     tasks = tasks.map(task => task.id === taskId ? { ...task, ...updatedTaskData } : task);
//     setTasks(tasks);
// };
