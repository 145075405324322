import privacyIcon from 'assets/privacy.webp';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import "../Pages.scss";


const Privacy = () => {
  return (
    <div>
      <Header />
      <main className="text__main">
        <section className="text__section">
          <h1>Privacy Policy</h1>
          <h2>Introduction</h2>
          <p>
            ZenMinder respects your privacy and is committed to protecting your personal data. This privacy
            policy will inform how we handle your personal data when using our applications and systems and
            will inform you about your privacy rights and how the law protects you.
          </p>
          <h2>Data we collect</h2>
          <p>
            When you use one of our applications or systems, we may collect, store and use the following types of
            personal data:
          </p>
          <ul>
            <li>Information about your device, including operating system and version.</li>
            <li>Information about how you use our app, such as lists created, items marked, and frequency of use.</li>
          </ul>
          <h2>How we use your data</h2>
          <p>We use your personal data to:</p>
          <ul>
            <li>Provide and improve our systems.</li>
            <li>Respond to your questions and requests.</li>
            <li>Analyze how our app is used to improve the user experience.</li>
          </ul>
          <h2>How we protect your data</h2>
          <p>We adopt security measures to prevent the loss, misuse or alteration of your personal information.</p>
          <h2>Your rights</h2>
          <p>
            You have the right to request access, correction or deletion of your personal data. You can also
            object to the processing of your personal data or request its restriction.
          </p>
          <h2>Changes to this policy</h2>
          <p>
            We may update this privacy policy from time to time. We recommend you check this page periodically to stay informed.
          </p>
          <p>
            Contact us if you have questions about this privacy policy or how we handle your personal information.
            Contact us at contact@zenminder.com.
          </p>
        </section>
        <p><img src={privacyIcon} alt="Artistic representation of our Privacy PolicY." className="img__background" />
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default Privacy;